import { useFormField } from '@kaliber/forms'
import { useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { InputText, InputEmail } from '/features/buildingBlocks/Input'
import styles from './FormField.css'

export function FormFieldText({ field, label, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError: invalid } = state

  return (
    <FormFieldBase className={layoutClassName} {...{ field }}>
      <label className={styles.label} htmlFor={name}>{label}</label>
      <InputText layoutClassName={styles.input} id={name} type="text" {...{ name, value, invalid }} {...eventHandlers} />
    </FormFieldBase>
  )
}

export function FormFieldEmail({ field, label, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError: invalid } = state
  return (
    <FormFieldBase className={layoutClassName} {...{ field }}>
      <label className={styles.label} htmlFor={name}>{label}</label>
      <InputEmail layoutClassName={styles.input} id={name} type="email" {...{ name, value, invalid }} {...eventHandlers} />
    </FormFieldBase>
  )
}

function FormFieldBase({ className, field, children }) {
  const { state } = useFormField(field)
  const error = useSafeError(state)

  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
      {error && <div className={styles.validation}>{error}</div>}
    </div>
  )
}

function useSafeError({ showError, error }) {
  const { __ } = useTranslate()
  const reportError = useReportError()

  if (!showError) return null

  try {
    return __`form-field-error-${error.id}`
  } catch (_) {
    reportError(`Missing error translation (form-field-error-${error.id})`)
    return __`form-field-error-generic`
  }
}
