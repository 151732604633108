import { useSprings, animated } from 'react-spring'
import { useForm } from '@kaliber/forms'
import { required, email } from '@kaliber/forms/validation'
import { useMutation } from '@tanstack/react-query'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { FormFieldText, FormFieldEmail } from '/features/buildingBlocks/FormField'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { routeMap } from '/routeMap'

import styles from './NewsletterForm.css'
import { useReportError } from '/machinery/ReportError'

export function NewsletterForm({ onSuccess, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const reportError = useReportError()
  const { mutate, isError, isSuccess, error } = useMutation({ mutationFn: subscribe, onSuccess })

  const [springs] = useSprings(3, (i) => ({
    from: { y: 50 },
    to: { y: 0 },
    delay: i * 50
  }))

  const { form: { fields }, submit } = useForm({
    initialValues: {
      name: '',
      email: ''
    },
    fields: {
      name: [required],
      email: [required, email]
    },
    onSubmit: handleSubmit
  })

  // Log any non-predictable error.
  if (error && error.message !== 'Member Exists') reportError(error)

  if (isSuccess) return <div>{String(__`newsletter-success`)}!</div>

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <form onSubmit={submit} className={styles.form} noValidate>
        <animated.div style={springs[0]}>
          <FormFieldText field={fields.name} label={__`newsletter-name`} />
        </animated.div>

        <animated.div style={springs[1]}>
          <FormFieldEmail field={fields.email} label={__`newsletter-email`} />
        </animated.div>

        <animated.div className={styles.button} style={springs[2]}>
          <ButtonPrimary type="submit" dataX='submit-newsletter-subscription-form'>
            {__`newsletter-submit`}
          </ButtonPrimary>
        </animated.div>
      </form>

      {isError && (
        <div className={styles.error}>
          {error.message === 'Member Exists'
            ? String(__`newsletter-member-exists`)
            : String(__`newsletter-error`)}
        </div>
      )}
    </div>
  )

  function handleSubmit(snapshot) {
    if (snapshot.invalid) return

    mutate({
      email: snapshot.value.email,
      name: snapshot.value.name,
      language
    })
  }

  async function subscribe({ email, name, language }) {
    const response = await fetch(routeMap.api.v1.subscribeToNewsletter(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, name, language })
    })

    const result = await response.json()

    if (result.statusCode !== 200) {
      throw new Error(result.title)
    }
  }
}
