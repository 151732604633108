import { dayjs } from '/machinery/dayjs'
import { OpeningHourRangeShort, OpeningHourRangeLong } from '/features/buildingBlocks/OpeningHour'
import styles from './OpeningHours.css'

export function OpeningHoursShort({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions }) {
  return <OpeningHoursBase openingHoursComponent={OpeningHourRangeShort} {...{ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions }} />
}

export function OpeningHoursLong({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions }) {
  return <OpeningHoursBase openingHoursComponent={OpeningHourRangeLong} {...{ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions }} />
}

function OpeningHoursBase({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions, openingHoursComponent: OpeningHours }) {
  const openingHours = openingHoursWithExceptions({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions })
  const compactOpeningHours = openingHours.reduce(
    (result, x) => {
      const [previous] = result.slice(-1)
      return previous && areOpeningHoursEqual(previous.openingHours, x.openingHours)
        ? [...result.slice(0, -1), { ...previous, until: x.date }]
        : [...result, { openingHours: x.openingHours, from: x.date, until: x.date }]
    },
    []
  )

  return (
    <div className={styles.componentBase}>
      {compactOpeningHours.map(x => (
        <p key={x.from}>
          <OpeningHours from={x.from} until={x.until} openingHours={x.openingHours} />
        </p>
      ))}
    </div>
  )
}

function areOpeningHoursEqual(a, b) {
  return (
    (a.closed && b.closed) ||
    (!a.closed && !b.closed && a.open === b.open && a.close === b.close)
  )
}

function openingHoursWithExceptions({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, exceptions }) {
  return [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
    .map((x, i) => {
      const date = dayjs().day(i + 1)
      const exception = exceptions?.find(e => e.date === date.format('YYYY-MM-DD'))
      return { date, openingHours: exception ?? x }
    })
}

