import styles from './Input.css'

export function InputText({ id, name, value, invalid = undefined, onChange, onFocus = undefined, onBlur = undefined }) {
  return <InputBase type="text" {...{ id, name, value, invalid, onChange, onFocus, onBlur }} />
}

export function InputEmail({ id, name, value, invalid = undefined, onChange, onFocus = undefined, onBlur = undefined }) {
  return <InputBase type="email" {...{ id, name, value, invalid, onChange, onFocus, onBlur }} />
}

function InputBase({ id, name, type, value, invalid = undefined, onChange, onFocus = undefined, onBlur = undefined }) {
  return <input className={cx(styles.componentBase, invalid && styles.invalid)} {...{ id, name, type, value, onChange, onFocus, onBlur }} />
}
