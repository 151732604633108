import { useTransition, animated } from 'react-spring'
import { useReportError } from '/machinery/ReportError'
import { PortableTextFooterContent } from '/features/buildingBlocks/PortableText'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { useClientConfig } from '/machinery/ClientConfig'
import { NewsletterForm } from '/features/buildingBlocks/NewsletterForm'
import { useWithGroupedBlocksType } from '/machinery/useWithGroupedBlocksType'
import { BoxWithRoundedCorners } from '/features/buildingBlocks/boxWithRoundedCorners/BoxWithRoundedCorners'
import { OpeningHoursShort } from '/features/buildingBlocks/OpeningHours'
import { useRouteData } from '/machinery/RouteData'
import { useTranslate } from '/machinery/I18n'

import crossIcon from '/images/icons/cross.raw.svg'
import iconFacebook from '/images/icons/facebook.raw.svg'
import iconInstagram from '/images/icons/instagram.raw.svg'

import styles from './Footer.css'

export function Footer({ contactColumns, newsLetterLink, openingHours }) {
  const config = useClientConfig()
  const { doc: page } = useRouteData()
  const [showNewsletterForm, setShowNewsletterForm] = React.useState(false)
  const { wrapperColor } = page ?? {}
  const { __ } = useTranslate()
  const currentYear = new Date().getFullYear()

  const newsletterTransition = useTransition(showNewsletterForm, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 400,
      friction: 30
    }
  })

  return (
    <BoxWithRoundedCorners>
      <footer className={styles.component} data-style-context={wrapperColor === 'white' ? 'blue' : 'white'} data-x='footer'>
        {newsletterTransition((style, visible) => visible && (
          <animated.div className={styles.newsletterForm} {...{ style }}>
            <NewsletterForm layoutClassName={styles.form} onSuccess={handleNewsletterSuccess} />
            <CloseButton
              onClick={() => { setShowNewsletterForm(false) }}
              dataX='close-newsletter-form'
              layoutClassName={styles.close}
            />
          </animated.div>
        ))}

        <div className={styles.layout}>
          <div className={styles.content}>
            {contactColumns && contactColumns.map((column, i) => (
              <Content key={i} content={column.content} {...{ openingHours }} />
            ))}
          </div>
          <div className={styles.contentRight}>
            <div className={styles.links}>
              <ButtonPrimary onClick={() => { setShowNewsletterForm(true) }} dataX='show-newsletter-form'>
                {__`newsletter-get`}
              </ButtonPrimary>
              <div className={styles.socialIcons}>
                <a target="_blank" data-x="link-to-facebook-page" className={styles.icon} href={config.socialLinks?.facebook} rel="noreferrer"><Icon icon={iconFacebook} /></a>
                <a target="_blank" data-x="link-to-instagram-page" className={styles.icon} href={config.socialLinks?.instagram} rel="noreferrer"><Icon icon={iconInstagram} /></a>
              </div>
            </div>
            <div className={styles.copyrightText}>
              <p>Illustrations Dick Bruna © copyright Mercis bv, 1953 – {currentYear}</p>
            </div>
          </div>
        </div>
      </footer>
    </BoxWithRoundedCorners>
  )

  function handleNewsletterSuccess() {
    setTimeout(
      () => { setShowNewsletterForm(false) },
      2000
    )
  }
}

function CloseButton({ onClick, dataX, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      className={layoutClassName}
      aria-label={String(__`close`)}
      type='button'
      data-x={dataX}
      {...{ onClick }}
    >
      <Icon icon={crossIcon} />
    </button>
  )
}

function Content({ content, openingHours }) {
  const reportError = useReportError()
  const contentWithGroupedBlocks = useWithGroupedBlocksType(content ?? [])

  return (
    <div className={styles.componentContent}>
      {
        contentWithGroupedBlocks.map(x => {
          switch (x._type) {
            case 'openingHours': return (
              <OpeningHoursShort key={x._key} {...openingHours} exceptions={openingHours.exceptions ?? []} />
            )

            case 'blocks': return (
              <PortableTextFooterContent key={x._key} value={x.blocks} />
            )

            default: {
              if (process.env.NODE_ENV === 'production') {
                reportError(new Error('Unknown content type ' + x._type))
                return null
              } else {
                return <p>Unknown content type {x._type}</p>
              }
            }
          }
        })
      }
    </div>
  )
}
